<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  page: {
    title: "File Manager",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    // Get hak akses
    const menu = "File Manager";
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Nama File", name: "nama" },
      { width: "auto", label: "Image", name: "-" },
      { width: "300px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      // isWrite: true,
      isLoading: true,
      tableFilter: {
        page: 0,
        per_page: 10,
        name: "",
      },
      tableData: [],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      columns: columns,
      sortKey: "id_file_manager",
      sortOrders: sortOrders,
      backend_url: process.env.VUE_APP_BACKEND_URL,
      previewUrl: "",
      showModalPreview: false,
      showModalAddFile: false,
      formData: {
        file: "",
        nama: "",
      },
    };
  },
  async mounted() {
    this.filterData = _.debounce(this.getFiles.bind(this), 500);
    await this.getFiles();
  },
  methods: {
    async getFiles() {
      this.isLoading = true;

      await axios({
        method: "get",
        url: `${process.env.VUE_APP_BACKEND_URL_VERSION}master/file-manager`,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        params: this.tableFilter,
      })
        .then((response) => {
          const responseData = response.data;
          if (responseData.meta.code === 200) {
            this.tableData = responseData.data.data;
            this.pagination.lastPage = responseData.data.last_page;
            this.pagination.currentPage = responseData.data.current_page;
            this.pagination.total = responseData.data.total;
            this.pagination.lastPageUrl = responseData.data.last_page_url;
            this.pagination.nextPageUrl = responseData.data.next_page_url;
            this.pagination.prevPageUrl = responseData.data.prev_page_url;
            this.pagination.from = responseData.data.from;
            this.pagination.to = responseData.data.to;
            this.pagination.links = responseData.data.links;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseData.meta.message,
            });
          }
        })
        .catch((error) => {
          console.error("err get files", error);
        });
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getFiles();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getFiles();
    },
    previewImage(url) {
      this.previewUrl = url;
      this.showModalPreview = true;
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      alert("Path berhasil di copy");
    },
    copyTag(url) {
      const text = `<img src="${process.env.VUE_APP_BACKEND_URL + url}">`;
      navigator.clipboard.writeText(text);
      alert("Tag berhasil di copy");
    },
    onFileChange(event) {
      // Handle file selection change
      this.formData.file = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      formData.append("file", this.formData.file);
      formData.append("nama", this.formData.nama);

      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL_VERSION}master/file-manager`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          if (responseData.meta.code === 200) {
            this.showModalAddFile = false; // Close the modal upon successful submission
            this.getFiles(); // Refresh file manager display
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseData.meta.message,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteFile(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/file-manager/" +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getFiles();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    // deleteFile(id) {
    //   // Handle deleting file
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between gap-2 mb-2">
              <div class="card-title">Daftar File</div>
              <div>
                <button
                  class="btn btn-success btn-sm mx-2"
                  @click="showModalAddFile = !showModalAddFile"
                  v-if="isWrite == 1"
                >
                  <i class="fa fa-plus me-1"></i>
                  Tambah File
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="form-group col-md-2">
                <label for="filterNamaFile">Nama File</label>
                <input
                  type="text"
                  id="filterNamaFile"
                  class="form-control"
                  placeholder="Filter Nama File"
                  v-model="tableFilter.name"
                  @input="filterData"
                />
              </div>
            </div>
            <hr />
            <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-4">
                  <table>
                    <tr>
                      <td>Show</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          id="showpaginatetable"
                          style="width: 100%"
                          v-model="tableFilter.per_page"
                          @change="getFiles()"
                        >
                          <option value="10" selected>10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </td>
                      <td>Entries</td>
                    </tr>
                  </table>
                </div>
                <!-- <div class="col-md-5">&nbsp;</div> -->
                <!-- <div class="col-md-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="searchTable"
                    placeholder="Cari Data ..."
                    v-model="tableFilter.search"
                    @input="getFiles()"
                  />
                </div> -->
              </div>
            </div>
            <Datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="isLoading">
                  <td class="text-center" colspan="99">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="!tableData.length">
                  <td class="text-center" colspan="99">Data Tidak Tersedia</td>
                </tr>
                <tr v-else v-for="(row, index) in tableData" :key="index">
                  <td>{{ index + pagination.from }}</td>
                  <td>{{ row.nama }}</td>
                  <td>
                    <div class="container-image" v-if="row.url">
                      <img
                        v-if="row.url"
                        :src="backend_url + row.url"
                        class="image"
                      />
                      <div
                        class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
                      >
                        <button
                          class="btn btn-success btn-sm"
                          @click="previewImage(backend_url + row.url)"
                        >
                          <i class="fa fa-search"></i> Preview
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="btn-group">
                      <a
                        href="javascript: void(0);"
                        class="btn btn-primary btn-sm"
                        @click="copyURL(row.url)"
                      >
                        <i class="fa fa-copy me-1"></i>
                        Copy PATH
                      </a>
                      <a
                        href="javascript: void(0);"
                        class="btn btn-success btn-sm"
                        @click="copyTag(row.url)"
                      >
                        <i class="fa fa-copy me-1"></i>
                        Copy Tag
                      </a>
                      <a
                        href="javascript: void(0);"
                        class="btn btn-danger btn-sm"
                        @click="deleteFile(row.id_file_manager)"
                        v-if="isWrite == 1"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Delete File
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Datatable>
            <Pagination :pagination="pagination" @to-page="toPage"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <b-modal v-model="showModalAddFile" title="Tambah File" hide-footer>
      <form @submit.prevent="submitForm" class="needs-validation" novalidate>
        <div class="mb-3">
          <label for="name" class="form-label">File</label>
          <input
            type="file"
            @change="onFileChange"
            accept="image/*"
            class="form-control"
            id="name"
            required
          />
        </div>
        <div class="mb-3">
          <label for="name" class="form-label">Nama</label>
          <input
            type="text"
            v-model="formData.nama"
            class="form-control"
            id="name"
            required
          />
        </div>
        <div class="mb-3">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>
  </Layout>
  <b-modal
    v-model="showModalPreview"
    title="Preview Gambar"
    centered
    hide-footer
    size="xl"
  >
    <div class="text-center" style="position: relative; width: 100%">
      <img :src="previewUrl" class="image" />
    </div>
  </b-modal>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
</style>
